html {
  direction: rtl;
}

.leaflet-container {
  height: 600px;
  width: 100%;
}

.cluster_tooltip {
  background-color: transparent;
  border: none;
  box-shadow: none;
  font-weight: bold;
  &::before {
    display: none;
  }
}

body {
  background-color: #f7f8fa !important;
}

.slick-prev:before,
.slick-next:before {
  color: black;
}

.leaflet-right.leaflet-top {
  z-index: 1001 !important;
}

#flint_details-wrapper-content {
  overflow: auto !important;
}

.data-gr-ext-installed {
  display: none;
}

.autocomplete-item {
  padding: 5px 5px 5px 35px;
  height: 40px;
  line-height: 26px;
  border-top: 1px solid #d9d9d9;
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.autocomplete-icon {
  display: block;
  position: absolute;
  top: 7px;
  bottom: 0;
  left: 8px;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: center center;
}

.autocomplete-icon.icon-localities {
  background-image: url(https://images.woosmap.com/icons/locality.svg);
}

.autocomplete-item:hover .autocomplete-icon.icon-localities {
  background-image: url(https://images.woosmap.com/icons/locality-selected.svg);
}

.autocomplete-item:hover {
  background-color: #f2f2f2;
  cursor: pointer;
}

.drawer_toolbar {
  margin-bottom: 10px;
}

// TODO: Remove this style after flint update
.MuiButtonBase-root.MuiButton-root.MuiButton-contained {
  display: inline-flex !important;
}
